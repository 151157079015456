import React, { Suspense, useEffect } from 'react';
import Helmet from 'react-helmet';
import {
  backgroundImageRoutes,
  excludeFooterRoutes,
  hideNavigationRoutes,
  hideSidebarRoutes,
  notAuthRouts,
} from './constants';
import { selectIsAuth, selectPathName } from '../../features/Auth';

import FAQBusiness from '../../../HelpCenterBusiness/FAQ';
import FAQCareer from '../../../HelpCenterCareer/FAQ';
import Footer from '../../../components/Footer';
import HelpCenterBusiness from '../../../HelpCenterBusiness';
import HelpCenterCareer from '../../../HelpCenterCareer';
import MainNavbar from '../../../components/MainNavbar';
import Navigation, { fetchUserPreferences } from '../../../components/Navigation';
import PrivacyPolicy from '../../../PrivacyPolicy';
import { RootState } from '../../App';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import Sidebar from '../../../components/Sidebar';
import { Switch, Redirect } from 'react-router-dom';
import TermsOfUse from '../../../TermsOfUse';
import Toast from '../../../components/Toast';
import UsingNoviopusBusiness from '../../../HelpCenterBusiness/UsingNoviopus';
import UsingNoviopusCareer from '../../../HelpCenterCareer/UsingNoviopus';
import clsx from 'clsx';
import { getActiveToast } from '../../../components/Toast/toastSlice';
import loadable from '@loadable/component';
import { match } from 'node-match-path';
import { selectStage } from 'App/features/OnBoardingStage';
import styles from './RouterWrapper.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectDirection, setDirection } from 'App/features/direction/directionSlice';
import { setLanguagesList } from 'App/features/languagesList/languagesListSlice';
import theme from '../../../assets/styles/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import themeRTL from '../../../assets/styles/themeRTL';

// const Login = loadable(props => {
//   import(
//     '../../features/SignIn'
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "signin-feature" */
//   ).then(module => {
//     props.store.injectReducer('signin', module.reducer);
//   });
//   return import(
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "login" */
//     'Login'
//   );
// });

const LoginSuccess = loadable(props => {
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "login" */
    'loginSuccess'
  );
});

const SignInRedirect = loadable(props => {
  return import('../../../Account/SignInRedirect');
});

const AccountRecovery = loadable(props => {
  import(
    '../../features/AccountSecurity'
    /* webpackPrefetch: true */
    /* webpackChunkName: "signup-feature" */
  ).then(module => {
    props.store.injectReducer('accountSecurity', module.reducer);
    props.store.injectSaga('accountSecuritySaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "signup" */
    'Account/AccountRecovery'
  );
});

// const CreateAccount = loadable(props => {
//   import(
//     '../../features/SignUp'
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "signup-feature" */
//   ).then(module => {
//     props.store.injectReducer('signup', module.reducer);
//     props.store.injectSaga('signupSaga', module.saga);
//   });
//   return import(
//     /* webpackPrefetch: true */
//     /* webpackChunkName: "signup" */
//     'Account/CreateAccount'
//   );
// });

const CheckMail = loadable(() => import('Account/CheckMail'));

const Profile = loadable(props => {
  import(
    '../../features/Profile'
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile-feature" */
  ).then(module => {
    props.store.injectReducer('profile', module.reducer);
    props.store.injectSaga('profileSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile" */
    'Account/Profile'
  );
});

const ProfileEducation = loadable(props => {
  import(
    '../../features/Education'
    /* webpackPrefetch: true */
    /* webpackChunkName: "education-feature" */
  ).then(module => {
    props.store.injectReducer('education', module.reducer);
    props.store.injectSaga('educationSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "education" */
    'Account/ProfileEducation'
  );
});

const ProfileExperience = loadable(props => {
  import(
    '../../features/Experience'
    /* webpackPrefetch: true */
    /* webpackChunkName: "experience-feature" */
  ).then(module => {
    props.store.injectReducer('experience', module.reducer);
    props.store.injectSaga('experienceSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "experience" */
    'Account/ProfileExperience'
  );
});

const ProfileSkills = loadable(props => {
  import(
    '../../features/Skills'
    /* webpackPrefetch: true */
    /* webpackChunkName: "skills-feature" */
  ).then(module => {
    props.store.injectReducer('skills', module.reducer);
    props.store.injectSaga('skillsSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "skills" */
    'Account/ProfileSkills'
  );
});

const ProfileLanguages = loadable(props => {
  import(
    '../../features/Languages'
    /* webpackPrefetch: true */
    /* webpackChunkName: "languages-feature" */
  ).then(module => {
    props.store.injectReducer('languages', module.reducer);
    props.store.injectSaga('languagesSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "languages" */
    'Account/ProfileLanguages'
  );
});

const ProfileJobSearch = loadable(props => {
  import(
    '../../features/JobSearch'
    /* webpackPrefetch: true */
    /* webpackChunkName: "job-search-feature" */
  ).then(module => {
    props.store.injectReducer('jobSearch', module.reducer);
    props.store.injectSaga('jobSearchSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "jobSearch" */
    'Account/ProfileJobSearch'
  );
});

const ProfileWorkPlace = loadable(props => {
  import(
    '../../features/WorkPlace'
    /* webpackPrefetch: true */
    /* webpackChunkName: "work-place-feature" */
  ).then(module => {
    props.store.injectReducer('workPlace', module.reducer);
    props.store.injectSaga('workPlaceSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "workPlace" */
    'Account/ProfileWorkPlace'
  );
});

const ProfileConnections = loadable(props => {
  import(
    '../../features/Connections'
    /* webpackPrefetch: true */
    /* webpackChunkName: "connections-feature" */
  ).then(module => {
    props.store.injectReducer('connections', module.reducer);
    props.store.injectSaga('connectionsSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "skills" */
    'Account/AddContacts'
  );
});

const ProfileInterests = loadable(props => {
  import(
    '../../features/Interests'
    /* webpackPrefetch: true */
    /* webpackChunkName: "skills-feature" */
  ).then(module => {
    props.store.injectReducer('interests', module.reducer);
    props.store.injectSaga('interestsSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "interests" */
    'Account/PersonalInterests'
  );
});

const AccountSecurity = loadable(props => {
  import(
    '../../features/AccountSecurity'
    /* webpackPrefetch: true */
    /* webpackChunkName: "signup-feature" */
  ).then(module => {
    props.store.injectReducer('accountSecurity', module.reducer);
    props.store.injectSaga('accountSecuritySaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "signup" */
    'Account/AccountSecurity'
  );
});

const ResetPassword = loadable(props => {
  import(
    '../../features/AccountSecurity'
    /* webpackPrefetch: true */
    /* webpackChunkName: "signup-feature" */
  ).then(module => {
    props.store.injectReducer('accountSecurity', module.reducer);
    props.store.injectSaga('accountSecuritySaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "signup" */
    'Account/ResetPassword'
  );
});

const AccountLanguageAndRegion = loadable(props => {
  import(
    '../../features/AccountLanguageAndRegion'
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile-feature" */
  ).then(module => {
    props.store.injectReducer('accountLanguageAndRegion', module.reducer);
    props.store.injectSaga('accountLanguageAndRegionSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile" */
    'Account/AccountLanguageAndRegion'
  );
});

const AccountStatus = loadable(props => {
  import(
    '../../features/AccountStatus'
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile-feature" */
  ).then(module => {
    props.store.injectReducer('accountStatus', module.reducer);
    props.store.injectSaga('accountStatusSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile" */
    'Account/AccountStatus'
  );
});

const PublicCompany = loadable(async props => {
  const [companiesAndJobsModule, profileModule] = await Promise.all([
    import('../../features/CompaniesAndJobs'),
    import('../../features/Profile'),
  ]);
  props.store.injectReducer('companiesAndJobs', companiesAndJobsModule.reducer);
  props.store.injectSaga('companiesAndJobsSaga', companiesAndJobsModule.saga);
  props.store.injectReducer('profile', profileModule.reducer);
  props.store.injectSaga('profileSaga', profileModule.saga);
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile" */
    'PublicCompany'
  );
});

const PublicJob = loadable(async props => {
  const [companiesAndJobsModule, profileModule] = await Promise.all([
    import('../../features/CompaniesAndJobs'),
    import('../../features/Profile'),
  ]);
  props.store.injectReducer('companiesAndJobs', companiesAndJobsModule.reducer);
  props.store.injectSaga('companiesAndJobsSaga', companiesAndJobsModule.saga);
  props.store.injectReducer('profile', profileModule.reducer);
  props.store.injectSaga('profileSaga', profileModule.saga);
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile" */
    'PublicJob'
  );
});

const JobSearch = loadable(async props => {
  const [companiesAndJobsModule, profileModule, signInModule] = await Promise.all([
    import('../../features/CompaniesAndJobs'),
    import('../../features/Profile'),
    import('../../features/SignIn'),
  ]);
  props.store.injectReducer('companiesAndJobs', companiesAndJobsModule.reducer);
  props.store.injectSaga('companiesAndJobsSaga', companiesAndJobsModule.saga);
  props.store.injectReducer('profile', profileModule.reducer);
  props.store.injectSaga('profileSaga', profileModule.saga);
  props.store.injectReducer('signin', signInModule.reducer);
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile" */
    'JobSearch'
  );
});

const JobSearchResults = loadable(async props => {
  const [companiesAndJobsModule, profileModule, signInModule] = await Promise.all([
    import('../../features/CompaniesAndJobs'),
    import('../../features/Profile'),
    import('../../features/SignIn'),
  ]);
  props.store.injectReducer('companiesAndJobs', companiesAndJobsModule.reducer);
  props.store.injectSaga('companiesAndJobsSaga', companiesAndJobsModule.saga);
  props.store.injectReducer('profile', profileModule.reducer);
  props.store.injectSaga('profileSaga', profileModule.saga);
  props.store.injectReducer('signin', signInModule.reducer);
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile" */
    'JobSearchResults'
  );
});

const Applications = loadable(async props => {
  // Here I need to create call the "reducer" of the server requests that I created on the applications page in the 'features' folder
  // ------------------------------------------
  // Here
  // ------------------------------------------

  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "profile" */
    'Applications'
  );
});

const Comments = loadable(props => {
  import(
    '../../features/Comments'
    /* webpackPrefetch: true */
    /* webpackChunkName: "skills-feature" */
  ).then(module => {
    props.store.injectReducer('comments', module.reducer);
    props.store.injectSaga('commentsSaga', module.saga);
  });
  return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "skills" */
    'CommentsSidebar'
  );
});

const UploadCV = loadable(() => import('Account/UploadCV'));
const AddNetworkPower = loadable(() => import('Account/AddNetworkPower'));

const routes = [
  // Public routes
  {
    path: '/',
    component: JobSearch,
    exact: true,
    isPrivate: true,
    withStore: true,
  },
  {
    path: '/company/:id',
    component: PublicCompany,
    exact: false,
    isPrivate: true,
    withStore: true,
  },
  {
    path: '/company/:id/jobs',
    component: PublicCompany,
    exact: true,
    isPrivate: true,
    withStore: true,
  },
  {
    path: '/jobs/:id',
    component: PublicJob,
    exact: false,
    isPrivate: true,
    withStore: true,
  },
  {
    path: '/search',
    component: JobSearch,
    exact: false,
    isPrivate: true,
    withStore: true,
  },
  {
    path: '/search-results',
    component: JobSearchResults,
    exact: false,
    isPrivate: true,
    withStore: true,
  },
  // {
  //   path: '/login',
  //   component: Login,
  //   withStore: true,
  //   exact: false,
  //   isPrivate: true,
  // },
  // {
  //   path: '/signup/passport/success',
  //   component: LoginSuccess,
  //   withStore: true,
  //   exact: true,
  //   isPrivate: true,
  // },
  {
    path: '/redirect/signin',
    component: SignInRedirect,
    withStore: true,
    exact: true,
    isPrivate: false,
  },
  // {
  //   path: '/account/create',
  //   component: CreateAccount,
  //   withStore: true,
  //   exact: true,
  //   isPrivate: true,
  // },
  {
    path: '/account/check',
    component: CheckMail,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/recovery',
    component: AccountRecovery,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  // Private routes
  {
    path: '/account/profile',
    component: Profile,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/resume',
    component: UploadCV,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/education',
    component: ProfileEducation,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/experience',
    component: ProfileExperience,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/skills',
    component: ProfileSkills,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/languages',
    component: ProfileLanguages,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/network',
    component: AddNetworkPower,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/add-contacts',
    component: ProfileConnections,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/interests',
    component: ProfileInterests,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/job-search',
    component: ProfileJobSearch,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/workplace',
    component: ProfileWorkPlace,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  // Account edit routes
  {
    path: '/account/profile/edit',
    component: Profile,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/resume/edit',
    component: UploadCV,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/education/edit',
    component: ProfileEducation,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/experience/edit',
    component: ProfileExperience,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/skills/edit',
    component: ProfileSkills,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/languages/edit',
    component: ProfileLanguages,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/network/edit',
    component: AddNetworkPower,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/add-contacts/edit',
    component: ProfileConnections,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/interests/edit',
    component: ProfileInterests,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/job-search/edit',
    component: ProfileJobSearch,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/workplace/edit',
    component: ProfileWorkPlace,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/career/job-search/edit',
    component: ProfileJobSearch,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/career/workplace/edit',
    component: ProfileWorkPlace,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/security',
    component: AccountSecurity,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/reset-password',
    component: ResetPassword,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/language',
    component: AccountLanguageAndRegion,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/account/status',
    component: AccountStatus,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/help/career',
    component: HelpCenterCareer,
    withStore: false,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/help/business',
    component: HelpCenterBusiness,
    withStore: false,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/help/career/faq',
    component: FAQCareer,
    withStore: false,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/help/career/using-noviopus',
    component: UsingNoviopusCareer,
    withStore: false,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/help/business/faq',
    component: FAQBusiness,
    withStore: false,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/help/business/using-noviopus',
    component: UsingNoviopusBusiness,
    withStore: false,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/terms',
    component: TermsOfUse,
    withStore: false,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/privacy',
    component: PrivacyPolicy,
    withStore: false,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/applications',
    component: Applications,
    withStore: true,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/comments',
    component: Comments,
    exact: true,
    isPrivate: true,
  },
];

interface WrapperProps {
  store: RootState;
}

const RouterWrapper: React.FC<WrapperProps> = ({ store }) => {
  const pathname = useAppSelector(selectPathName);
  const activeToast = useAppSelector(getActiveToast);
  const showMainNavbar = pathname !== '/login';
  const isAuth = useAppSelector(selectIsAuth);

  const stage = useAppSelector(selectStage) as any;

  const isHideNavigationRoute = hideNavigationRoutes.some(rout => match(rout, pathname).matches);
  const isHideSidebarRoute = hideSidebarRoutes.some(rout => match(rout, pathname).matches);
  const showBackgroundImage = backgroundImageRoutes.some(rout => match(rout, pathname).matches);
  const notAuth = notAuthRouts.some(rout => match(rout, pathname).matches);
  const isJobPage = match('/jobs/:id', pathname).matches;
  const showNavigation = !notAuth && !isHideNavigationRoute;
  const showSidebar = !notAuth && !isHideSidebarRoute;

  const dispatch = useAppDispatch();
  const direction = useAppSelector(selectDirection);

  async function getListOfLanguages() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API}/career/languages/loadedLanguages`);
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getListOfLanguages().then(data => {
      dispatch(setLanguagesList(data));
    });
  }, []);

  useEffect(() => {
    if (isAuth && !localStorage.getItem('direction')) {
      fetchUserPreferences().then(data => {
        // setting language direction
        const langDirection = data ? data.language.direction.toLowerCase() : 'ltr';
        localStorage.setItem('direction', langDirection);
        dispatch(setDirection(langDirection));
        document.getElementsByTagName('html')[0].setAttribute('dir', langDirection);
      });
    } else {
      dispatch(setDirection(localStorage.getItem('direction')!));
    }
    if (!isAuth) {
      console.log('Not is auth ====>', isAuth);
    }
  }, []);

  useEffect(() => {
    const direction = localStorage.getItem('direction');
    direction && document.getElementsByTagName('html')[0].setAttribute('dir', direction);
  }, []);

  const isMargin0 = () => {
    let result = false;
    // if (pathname.includes('/account/create') || pathname.includes('/login')) result = true
    if (pathname.includes('/account/profile') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/resume') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/account/education') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/account/experience') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/account/skills') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/languages') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/account/job-search') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/account/workplace') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/account/add-contacts') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/account/interests') && !pathname.includes('/edit')) result = true;
    if (pathname.includes('/account/network') && !pathname.includes('/edit')) result = true;
    return result;
  };

  function getWrapperMargin(direction: string): {
    marginLeft: string;
    marginRight: string;
  } {
    if (isMargin0()) {
      return { marginLeft: '0', marginRight: '0' };
    }
    if (direction === 'rtl') {
      return { marginRight: '280px', marginLeft: '0' };
    } else return { marginLeft: '280px', marginRight: '0' };
  }

  return (
    <ThemeProvider theme={direction === 'rtl' ? themeRTL : theme}>
      <div className={pathname === '/login' ? clsx(styles.main, styles.mainLogin) : styles.main}>
        <Helmet titleTemplate="Noviopus Career | %s" defaultTitle="">
          <meta name="description" content="Noviopus Career helps to connect recruiters and job seekers." />
        </Helmet>
        {showMainNavbar && (
          <MainNavbar isAuth={isAuth} isOnboard={stage?.stage === '9'} color={!isJobPage ? 'primary' : 'inherit'} />
        )}
        {showNavigation && <Navigation />}
        <div
          style={getWrapperMargin(direction)}
          className={clsx(
            styles.content,
            showNavigation && styles.contentWithNavigation,
            showBackgroundImage && styles.contentWithBackgroundImage,
          )}>
          <Suspense fallback={<div className={styles.loadingPage} />}>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} store={store} showSidebar={showSidebar} />
              ))}
            </Switch>
          </Suspense>
        </div>
        {activeToast && <Toast {...activeToast} />}
        {excludeFooterRoutes.indexOf(pathname) === -1 && <Footer />}
      </div>
    </ThemeProvider>
  );
};

export default RouterWrapper;
